var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage,"show-select":false},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Reklamlar","icon":"mdi-advertisements","add-route":{
            name: 'admin.ads.create',
          },"hide-edit":"","search":_vm.search.query},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"body.append",fn:function({ headers }){return [(_vm.list.length > 0)?_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}}):_vm._e()]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'admin.ads.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.cluster_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'definitions.clusters.edit',
            params: {
              id: item.cluster_id,
            },
          }}},[_vm._v(" "+_vm._s(item.cluster_name)+" ")])]}},{key:"item.is_active",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.click_count",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"value":value}})]}},{key:"item.unique_click_count",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"value":value}})]}},{key:"item.invoice_payment_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.created_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}},{key:"item.updated_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}},{key:"item.actions",fn:function({ item }){return [_c('rs-table-button-delete',{attrs:{"confirmed":"","loading":_vm.isLoading},on:{"deleted":function($event){return _vm.handleDeleteClick(item)}}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }